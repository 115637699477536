<template>
  <el-card shadow="never" v-loading="searchForm.loading">
    <el-form :inline="true" :model="searchForm" style="height: 30px;">
      <el-form-item style="width: 60px;">
        <el-checkbox v-model="searchForm.checked" label="Option 1" size="large" @change="changeSelect"
          >全选</el-checkbox
        >
      </el-form-item>

      <el-form-item label="门店区域">
        <el-cascader
          ref="casRef"
          v-model="value"
          :options="areaOptions"
          clearable
          filterable
          @change="changeArea()"
          :show-all-levels="false"
          collapse-tags
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-select-v2
          v-model="searchForm.searchShop"
          :options="searchForm.shopLists"
          @keyup.enter="getShops"
          filterable
          clearable
          placeholder="请选择门店"
        />
        <!-- <el-input v-model="searchForm.searchShop" placeholder="搜索门店" @keyup.enter="getShops" clearable /> -->
      </el-form-item>
      <el-form-item>
        <el-button @click="getShops">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-divider border-style="double" />
    <div class="shopsShow" v-if="shops.length !== 0">
      <el-checkbox-group v-model="selectStoreData" @change="handleCheckedCitiesChange">
        <el-checkbox
          :title="item.label"
          :label="item.value"
          size="large"
          class="my-checkbox"
          style="margin-right: 10px; margin-left:0px"
          border
          v-for="(item, index) in shops"
          :key="index"
          >{{ item.value }}_{{ item.label }}</el-checkbox
        >
      </el-checkbox-group>
    </div>
    <el-empty description="当前区域暂无门店，请重新选择区域" :image-size="200" v-else />
  </el-card>
</template>
<script setup>
import commonApi from '@/api'
import API from '../service/api'
import { onMounted, reactive, ref, toRaw, defineEmit, watchEffect, defineProps, computed } from 'vue'
const props = defineProps({
  storeData: {
    type: Array,
    default: () => [],
  },
})
const selectStoreData = ref([])

const mySelectStoreDate = computed(() => {
  return props.storeData
})
watchEffect(() => {
  selectStoreData.value = mySelectStoreDate.value
})

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'children',
  checkStrictly: true,
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  const getTreeData = data => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].children.length < 1) {
        // children若为空数组，则将children设为undefined
        data[i].children = undefined
      } else {
        // children若不为空数组，则继续 递归调用 本方法
        getTreeData(data[i].children)
      }
    }
    return data
  }
  commonApi.getNewArea().then(res => {
    areaOptions.value = getTreeData(res.data)
  })
}
const casRef = ref()
const changeArea = () => {
  searchForm.searchShop = ''
  searchForm.regionCode = casRef.value.getCheckedNodes().map(item => item.data.code)
  getShops()
  getAllShops()
}

const emit = defineEmit(['changeStore'])
const searchForm = reactive({
  loading: false,
  checked: false,
  geoCode: [],
  shopLists: [],
  optionsProps: {
    label: 'name',
    children: 'children',
    value: 'code',
    checkStrictly: true,
  },
  searchShop: '',
  regionCode: '',
})

const shops = ref([])

onMounted(() => {
  getShops()
  getAreas()
  getAllShops()
})

const handleCheckedCitiesChange = item => {
  emit('changeStore', {
    data: item,
    isOneClick: true,
  })

  if (isAllSelectHandle()) {
    searchForm.checked = true
  } else {
    searchForm.checked = false
  }
}
//  获取门店
const getShops = () => {
  getAllShops()
}

//是否需要全选或者全不选
const isAllSelectHandle = () => {
  let tag = true
  shops.value.forEach(item => {
    let index = selectStoreData.value.findIndex(i => i === item.value)
    if (index === -1) {
      tag = false
    }
  })
  return tag
}

//获取全部门店
const getAllShops = () => {
  searchForm.loading = true

  let params = {}
  if (casRef.value.getCheckedNodes().length > 0) {
    params.withInOrgans = casRef.value.getCheckedNodes().map(item => item.data.code)
  }
  commonApi
    .getNewShop(params)
    .then(res => {
      if (res.code === '200') {
        searchForm.shopLists = res.data.map(item => {
          return { value: item.code, label: item.code + '--' + item.name }
        })
        if (searchForm.searchShop) {
          shops.value = res.data
            .map(item => ({
              label: item.name,
              value: item.code,
            }))
            .filter(item => item.value == searchForm.searchShop)
        } else {
          shops.value = res.data.map(item => ({
            label: item.name,
            value: item.code,
          }))
        }
        if (isAllSelectHandle()) {
          searchForm.checked = true
        } else {
          searchForm.checked = false
        }
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (searchForm.loading = false))
}

const changeSelect = a => {
  if (a) {
    emit('changeStore', {
      data: shops.value.map(item => item.value),
      isAll: true,
    })
  } else {
    emit('changeStore', {
      data: shops.value.map(item => item.value),
      isAll: false,
    })
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
::v-deep(.el-checkbox-group) {
  display: flex;
  flex-wrap: wrap;
}
::v-deep(.el-checkbox) {
  display: flex;
  align-items: center;
  width: min-content;
  margin-bottom: 10px;
  .el-checkbox__label {
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
